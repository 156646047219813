<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="trainingCourseForm" ref="trainingCourseForm" :model="trainingCourseForm" label-position="right" @submit.native.prevent>
      <el-form-item label="交易时间：" label-width="70px">
        <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 200px !important" v-model="trainingCourseForm.paymentStartTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingCourseForm'], 'paymentEndTime', true)" placeholder="开始日期" />
        -
        <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 200px !important" v-model="trainingCourseForm.paymentEndTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingCourseForm'], 'paymentStartTime', true)" placeholder="结束日期" />
      </el-form-item>
      <el-form-item label="商品名称：" label-width="70px">
        <el-input class="zwx-input" v-model.trim="trainingCourseForm.goodsNames" placeholder="请输入商品名称" style="width: 200px !important" clearable maxlength="50" />
      </el-form-item>
      <el-form-item label="订单编号：" label-width="70px">
        <el-input class="zwx-input" v-model.trim="trainingCourseForm.orderNo" placeholder="请输入订单编号" style="width: 200px !important" clearable maxlength="50" />
      </el-form-item>
      <div>
        <el-form-item label="支付状态：" label-width="70px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="orderStateArr">
            <el-checkbox class="zwx-checkbox" :label="'00'">待支付</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="'01'">支付成功</el-checkbox>
            <!--          <el-checkbox class="zwx-checkbox" :label="'02'">交易完成</el-checkbox>-->
            <el-checkbox class="zwx-checkbox" :label="'03'">退款申请</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="'04'">退款成功</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="'99'">订单取消</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrainingCourse(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="reportDownload">导出</el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="tableList" border stripe tooltip-effect="light">
      <el-table-column prop="orderNo" label="订单编号" width="220" header-align="center" align="left"></el-table-column>
      <el-table-column prop="activityName" label="培训班名称" min-width="100" header-align="center" align="left"></el-table-column>
      <el-table-column prop="goodsNames" label="商品名称" min-width="100" header-align="center" align="left"></el-table-column>
      <el-table-column prop="orderStateArr" label="支付状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.orderState == '00'">待支付</span>
          <span v-if="scope.row.orderState == '01'">支付成功</span>
          <!--          <span v-if="scope.row.orderState == '02'">交易完成</span>-->
          <span v-if="scope.row.orderState == '03'">退款申请</span>
          <span v-if="scope.row.orderState == '04'">退款成功</span>
          <span v-if="scope.row.orderState == '99'">订单取消</span>
        </template>
      </el-table-column>
      <el-table-column prop="paymentTime" label="交易时间" width="170" header-align="center" align="center"></el-table-column>
      <el-table-column prop="totalAmount" label="订单金额" width="120" header-align="center" align="center"></el-table-column>
      <el-table-column prop="orderDescription" label="订单描述" min-width="140" header-align="center" align="left"></el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="trainingCourseForm.currentPage" :pageSize="trainingCourseForm.pageSize" :total="trainingCourseForm.total" @currentChange="queryTrainingCourse" />
  </div>
</template>

<script>
export default {
  name: 'IncomeExpenditureDetails',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      trainingCourseForm: {
        orderNo: '', //订单编号
        goodsNames: '', //商品名称
        paymentStartTime: '', //线下培训开始时间
        paymentEndTime: '', //线下培训结束时间
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      orderStateArr: ['01'],
      tableList: [],
      signInQrCodeVisible: false,
      bindQrCodePath: '',
      bindQrCodeName: '',
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryTrainingCourse(1)
  },
  mounted() {
    this.queryTrainingCourse(1)
  },
  methods: {
    reportDownload() {
      this.$FormatData.exportExcel(this.api + '/training/platform/exportActivityMainOrderDetailList-1', { ...this.trainingCourseForm, orderStateArr: this.orderStateArr, })
    },
    queryTrainingCourse(currentPage) {
      this.trainingCourseForm.currentPage = currentPage
      let data = {
        ...this.trainingCourseForm,
        orderStateArr: this.orderStateArr,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getMyOrderDetailList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.tableList = data.orderVoList
            this.trainingCourseForm.pageSize = data.pageSize
            this.trainingCourseForm.total = data.total
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.bindQrCodeDialog /deep/.el-dialog {
  width: fit-content;
  min-width: auto !important;
}
.zwx-select-student /deep/.el-input__inner {
  width: 200px !important;
}
</style>
