<template>
  <div class="job_info-edit-page">
    <el-form class="zwx-form edit-form" key="msForm" :model="msForm" :rules="rules" ref="msForm" label-position="right" @submit.native.prevent>
      <div>
        <div class="edit-row">
          <el-form-item label-width="90px" label="岗位(工种)：" prop="jobName">
            <el-input style="width: 387px !important;" class="zwx-input" v-model.trim="msForm.jobName" maxlength="50" placeholder="岗位（工种）名称" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="151px" label="接触职业病危害因素：" prop="exposureHazards">
            <el-radio-group style="min-width: 100px !important" class="zwx-radio-group" v-model="msForm.exposureHazards" @change="exposureHazardsChange">
              <el-radio class="zwx-radio" :label="true">是</el-radio>
              <el-radio class="zwx-radio" :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <base-headline title="防护用品" class="no-bottom">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addPPE">添加</el-button>
        </base-headline>
        <el-table class="zwx-table" row-key="ppeList" :data="msForm.ppeList" border stripe tooltip-effect="light" :max-height="msForm.exposureHazards?250:540">
          <el-table-column prop="ppeTypeCode" label="类别" width="400" header-align="center" align="center" :render-header="addRedStar">
            <template slot-scope="scope">
              <el-form-item :prop="'ppeList.' + scope.$index + '.ppeTypeCode'" :rules="rules.ppeTypeCode">
                <cascader-options-single class="zwx-cascader-single-ppeCode" ref="mscCascader" :options="simpleCodeList" v-model="scope.row.ppeTypeCode" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" expandTrigger="hover" @change="changeCode($event, scope.$index)" placeholderText="请选择防护用品类别"></cascader-options-single>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ppeName" label="名称" width="300" header-align="center" align="center" :render-header="addRedStar">
            <template slot-scope="scope">
              <el-form-item :id="'ppeList' + scope.$index" :prop="'ppeList.' + scope.$index + '.ppeName'" :rules="rules.ppeName">
                <el-select class="zwx-select" v-model="scope.row.ppeName" filterable allow-create default-first-option placeholder="请输入防护用品名称" @change="ppeNameChange($event, scope.$index)">
                  <el-option v-for="item in scope.row.nameOptions" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ppeSpec" label="规格" width="300" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :id="'ppeList' + scope.$index" :prop="'ppeList.' + scope.$index + '.ppeSpec'">
                <el-select class="zwx-select" v-model="scope.row.ppeSpec" filterable allow-create default-first-option placeholder="请输入防护用品规格" @change="ppeSpecChange($event, scope.$index)">
                  <el-option v-for="item in scope.row.specOptions" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="160" header-align="left" align="left">
            <template slot-scope="scope">
              <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletePPE(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <base-headline title="接触职业病危害因素明细" class="no-bottom no-top" v-show="this.hazardShow"></base-headline>
        <div class="multi-select-main" v-show="this.hazardShow">
          <!-- 左侧危害因素树形 -->
          <div class="left">
            <div class="search-row">
              <el-input placeholder="请输入危害因素或CAS号查询" style="width:100% !important" class="zwx-input" :ref="searchRef" v-model="searchInput" >
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <div class="options">
              <zwx-scrollbar-y style="height: 337px;" :ref="treeScrollbarRef">
                <el-tree :data="showDataList" :ref="treeRef" @check-change="treeCheckChange" :filter-node-method="filterNodeMethod" node-key="rid" children="subList" show-checkbox accordion>
                  <div class="custom-tree-node" slot-scope="{ node, data }" style="display:flex;justify-content: space-between;width:100%">
                    <div class="hazard-name-hidden">{{ node.label }}</div>
                    <div :title="data.cas" style="padding-right:10px;font-size:14px">{{ data.cas }}</div>
                  </div>
                </el-tree>
              </zwx-scrollbar-y>
            </div>
          </div>
          <!-- 右侧已选 -->
          <div class="right">
            <div class="right-check">已选危害因素（{{ $zwxBase.verifyIsNotBlank(msForm.showCheckDataList) ? msForm.showCheckDataList.length : 0 }}）</div>
            <zwx-scrollbar-y style="height: 400px;" :ref="checkScrollbarRef">
              <div class="add-new-job-css">
                <el-table ref="msForm" class="zwx-table" style="width: 100%;" height="363" row-key="rid" :data="msForm.showCheckDataList" border stripe tooltip-effect="light">
                  <el-table-column label="分类" width="350" header-align="center" align="left" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <span>{{ scope.row.hazardName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="名称" width="300" header-align="center" align="left">
                    <template slot-scope="scope">
                      <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                        <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" header-align="left" align="left">
                    <template slot-scope="scope">
                      <el-button class="zwx-button zwx-button-text-26" type="danger" @click="showCheckDataListDelete(scope.$index)">删除</el-button>
                      <!-- <i style="cursor: pointer;" class="el-icon-delete" @click="showCheckDataListDelete(scope.$index)"></i> -->
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </zwx-scrollbar-y>
          </div>
        </div>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="saveAccidentDisposal">保存</el-button>
      <el-button v-if="ifAdd" class="zwx-button zwx-button-icontext-32" @click="addContinuity">连续添加</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'JobInfoDetail',
  data() {
    return {
      api: this.$store.state.api,
      searchRef: 'input_' + this.$system.uuid(),
      treeScrollbarRef: 'tree_' + this.$system.uuid(),
      treeRef: 'tree_' + this.$system.uuid(),
      checkScrollbarRef: 'check_' + this.$system.uuid(),
      users: this.$store.state.users,
      employerUuid: this.$route.params.employerUuid,
      type: this.$route.params.type,
      entrance:this.$route.params.entrance,
      simpleCodeList: [], //防护用品下拉数据源
      fhCodeList: [], //防护用品平铺数据源
      msForm: {
        rid: '',
        jobName: '',
        exposureHazards: true,
        ppeList: [],
        hazardList: [], //危害因素集合
        showCheckDataList: [],
      },
      hazardShow: true,
      saveButtonDisabled: '',
      dataList: [],
      input: '',
      searchInput: '', //危害因素搜索条件
      showDataList: [], //危害因素数据源
      ifJustLeaf: true, //是否叶子节点
      checkDataList: [], //左侧已勾选
      echoCheckDataCodeList: [], //回显勾选危害因素
      excludeCodeList: [],
      hazardMap: null,
      editCode: '',
      ifAdd:true, // 是否添加页/复制添加进入
      ifContinuous:false, // 是否点击了连续添加
      rules: {
        jobName: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
        exposureHazards: [{ required: true, message: '请选择是否接害', trigger: ['change', 'blur'] }],
        hazardsNameDetail: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        ppeTypeCode: [{ required: true, message: '请选择类别', trigger: ['change', 'blur'] }],
        ppeName: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
      },
    }
  },
  activated() {},
  computed: {},
  watch: {
    // tree筛选
    searchInput() {
      this.search(this.searchInput)
    },
  },
  created() {},
  mounted() {
    if (this.$zwxBase.verifyIsNotBlank(this.$route.params.type)) {
      if (this.$route.params.type == 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.ifAdd = false
      }
      if (this.$route.params.type == 'add' || this.$route.params.type == 'copyAdd') {
        this.$emit('breadcrumb', '添加', true)
      }
      //   // 查询岗位详情
      //   this.getJobInfo(this.$route.params.rid)
    }
    // 获取防护用品数据源
    this.getJobPpeList()
    // 获取危害因素 树形
    this.msHazardsSelectSearch()
  },

  methods: {
    // 给表头加必填符号*
    addRedStar(h, { column }) {
      return [
        h("span", { style: "color: red" }, "*"),
        h("span", " " + column.label),
      ];
    },
    ppeNameChange(value, index) {
      var ppeName = this.msForm.ppeList[index].ppeName
      if (this.$zwxBase.verifyIsNotBlank(ppeName)) {
        if (ppeName.length > 20) {
          this.$set(this.msForm.ppeList[index], 'ppeName', ppeName.substring(0, 19))
        }
      }
    },
    ppeSpecChange(value, index) {
      var ppeSpec = this.msForm.ppeList[index].ppeSpec
      if (this.$zwxBase.verifyIsNotBlank(ppeSpec)) {
        if (ppeSpec.length > 200) {
          this.$set(this.msForm.ppeList[index], 'ppeSpec', ppeSpec.substring(0, 199))
        }
      }
    },
    /**
     * 防护用品数据源
     */
    getJobPpeList() {
      var list = []
      this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3061', data => {
        list = data['3061']
        this.fhCodeList = list
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          this.simpleCodeList = this.manyListFul(list, '')
        }
      })
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    /**
     * 类别选中改变名称
     */
    changeCode(row, index) {
      let data = {
        employerUuid: this.employerUuid,
        ppeTypeCode: row,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getEmployerPpeListByTypeCode-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$set(this.msForm.ppeList[index], 'nameOptions', data.nameList)
            this.$set(this.msForm.ppeList[index], 'specOptions', data.specList)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 获取岗位详情
     */
    getJobInfo(rid) {
      let data = {
        // 用人单位id 必填
        rid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getJobInfo-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.rid = data.jobInfo.rid
            if (this.$route.params.type == 'edit'){
              this.msForm.jobName = data.jobInfo.jobName
            }
            this.msForm.exposureHazards = data.jobInfo.exposureHazards
            // 显示危害因素左右内容
            if (this.msForm.exposureHazards) {
              this.hazardShow = true
            } else {
              this.hazardShow = false
            }
            //  防护用品数据 处理
            if (this.$zwxBase.verifyIsNotBlank(data.jobInfo.jobPpeList)) {
              this.msForm.ppeList = data.jobInfo.jobPpeList
            }
            // 危害因素左侧已勾选数据 处理
            this.checkDataList = []
            this.msForm.showCheckDataList = []
            var selectList = []
            if (this.$zwxBase.verifyIsNotBlank(data.jobInfo.hazardList)) {
              data.jobInfo.hazardList.forEach(item => {
                selectList.push(item.hazardsCode)
              })
              var map = new Map()
              data.jobInfo.hazardList.forEach(item => {
                selectList.push(item.hazardsCode)
                map.set(item.hazardsCode, item.hazardsNameDetail)
              })
              this.$nextTick(() => {
                // 设置集合回显
                this.setEchoCheckDataCodeList(selectList, map)
              })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 保存
     */
    saveAccidentDisposal() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          // 涉及危害因素列表 数据处理
          var hazardList = []
          // 是接害
          if (this.msForm.exposureHazards) {
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.showCheckDataList)) {
              this.msForm.showCheckDataList.forEach(item => {
                hazardList.push({
                  hazardsSort: item.hazardsSort, //大类
                  hazardsCode: item.codeNo, // 编码
                  hazardsName: item.hazardName, //危害因素名称
                  hazardsNameDetail: this.$zwxBase.verifyIsBlank(item.hazardsNameDetail) ? item.hazardName : item.hazardsNameDetail, //自定义危害因素名称
                })
              })
            } else {
              this.$system.notify('错误', '请至少选择一个危害因素', 'error')
              return
            }
          }
          if (this.type == 'copyAdd' || this.type == 'add') {
            this.msForm.rid = ''
          }
          let data = {
            // 用人单位id 必填
            employerUuid: this.employerUuid,
            rid: this.msForm.rid,
            jobName: this.msForm.jobName,
            exposureHazards: this.msForm.exposureHazards,
            jobPpeList: this.msForm.ppeList,
            hazardList: this.msForm.exposureHazards ? hazardList : [],
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/zky/employer/addOrModifyJobInfo-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', data.mess, 'success')
                // 判断是从部门岗位进入的页面 还是 岗位工种进入的
                if (this.entrance == 'job'){
                  this.$router.push({
                    name: 'JobInfo',
                    params: { fresh: true },
                  })
                }
                if (this.entrance == 'depart'){
                  this.$router.push({
                    name: 'DepartmentStation',
                    params: { fresh: true },
                  })
                }
                // 刷新
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        }
      })
    },

    /**
     * 连续添加 岗位
     */
    addContinuity() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          var hazardList = []
          // 是接害
          if (this.msForm.exposureHazards) {
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.showCheckDataList)) {
              this.msForm.showCheckDataList.forEach(item => {
                hazardList.push({
                  hazardsSort: item.hazardsSort, //大类
                  hazardsCode: item.codeNo, // 编码
                  hazardsName: item.hazardName, //危害因素名称
                  hazardsNameDetail: this.$zwxBase.verifyIsBlank(item.hazardsNameDetail) ? item.hazardName : item.hazardsNameDetail, //自定义危害因素名称
                })
              })
            } else {
              this.$system.notify('错误', '请至少选择一个危害因素', 'error')
              return
            }
          }
          let data = {
            // 用人单位id 必填
            employerUuid: this.employerUuid,
            rid: '',
            jobName: this.msForm.jobName,
            exposureHazards: this.msForm.exposureHazards,
            jobPpeList: this.msForm.ppeList,
            hazardList: this.msForm.exposureHazards ? hazardList : [],
          }
          this.$emit('loading', true)
          this.$system.postJson(
                  this.api + '/zky/employer/addOrModifyJobInfo-1',
                  data,
                  true,
                  true,
                  data => {
                    this.$emit('loading', false)
                    if (data.type == '00') {
                      this.$system.notify('成功', data.mess, 'success')
                      this.$nextTick(() => {
                        this.ifContinuous = true
                        this.$refs['msForm'].clearValidate()
                        this.searchInput = ''
                        this.msForm.rid = ''
                        this.msForm.jobName = ''
                        this.msForm.exposureHazards = true //默认选是
                        this.hazardShow = true // 展开危害因素
                        this.msForm.hazardList = []
                        this.searchInput = ''
                        this.showDataList = []
                        this.checkDataList = []
                        this.msForm.showCheckDataList = []
                        this.echoCheckDataCodeList = []
                        this.excludeCodeList = []
                        this.hazardMap = null
                        this.editCode = ''
                        this.msForm.ppeList = []
                        this.msHazardsSelectSearch()
                      })

                    } else if (data.type === '99') {
                      this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                    } else {
                      this.$system.notify('错误', data.mess, 'error')
                    }
                  },
                  data => {
                    this.$emit('loading', false)
                    this.$system.notify('错误', data.mess, 'error')
                  }
          )
        }
      })
    },
    /**
     * 是否接害按钮 change事件
     */
    exposureHazardsChange() {
      // 不接害时
      if (!this.msForm.exposureHazards) {
        this.hazardShow = false
      } else {
        this.hazardShow = true
      }
    },
    /**
     * 添加防护用品
     */
    addPPE() {
      let data = {
        rid: '',
        ppeTypeCode: '',
        ppeName: '',
        ppeSpec: '',
      }
      this.msForm.ppeList.push(data)
    },
    /**
     * 删除防护用品
     */
    deletePPE(index) {
      this.msForm.ppeList.splice(index, 1)
    },
    //危害因素查询
    msHazardsSelectSearch: function() {
      let data = {}
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/basic/info/getHazardsStandardExpList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            var dataList = data.dataList
            if (this.$zwxBase.verifyIsNotBlank(dataList)) {
              dataList.forEach(item => {
                if (this.$zwxBase.verifyIsBlank(item.upcodeNo)) {
                  item.disabled = true
                }
              })
            }
            this.setLinearDataList(dataList)
            if (this.$zwxBase.verifyIsNotBlank(this.$route.params.rid)) {
              // 获取岗位详情
              // 连续添加不查岗位详情
              if (!this.ifContinuous){
                this.getJobInfo(this.$route.params.rid)
              }
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    // 线形转树形第一级
    linearDataListTreeFisrt(dataList) {
      this.showDataList.length = 0
      for (let i = 0, length = dataList.length; i < length; i++) {
        const data = dataList[i]
        if (data['upcodeNo'] !== undefined && data['upcodeNo'] !== null && data['upcodeNo'] !== '' && data['upcodeNo'] !== data['codeNo']) continue
        this.showDataList.push(dataList[i])
        dataList.splice(i, 1)
        length--
        i--
      }
    },
    // 线形转树形递归
    linearDataListTreeRecursion(showDataList, dataList) {
      for (let i = 0, length = showDataList.length; i < length; i++) {
        for (let j = 0, jLength = dataList.length; j < jLength; j++) {
          if (showDataList[i]['codeNo'] !== dataList[j]['upcodeNo']) continue
          if (showDataList[i].children === undefined) showDataList[i].children = []
          dataList[j].dataLevelCode = showDataList[i].dataLevelCode + '.' + dataList[j]['codeNo']
          showDataList[i].children.push(dataList[j])
          dataList.splice(j, 1)
          jLength--
          j--
        }
        if (showDataList[i].children !== undefined) this.linearDataListTreeRecursion(showDataList[i].children, dataList)
      }
    },
    // 回显
    echoCheckDataList() {
      this.input = ''
      this.search('')
      if (this.showDataList.length === 0) return
      this.msForm.showCheckDataList.length = 0
      this.checkDataList.length = 0
      for (let i = 0, length = this.echoCheckDataCodeList.length; i < length; i++) {
        const index = this.dataList.findIndex(data => {
          return data['codeNo'] === this.echoCheckDataCodeList[i]
        })
        if (index === -1) continue
        if (this.checkIfExcludeCode(this.dataList[index])) continue
        this.checkDataList.push(this.dataList[index])
      }
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
      this.msForm.showCheckDataList = this.$refs[this.treeRef] && this.$refs[this.treeRef].getCheckedNodes(this.ifJustLeaf)
      this.input = this.checkDataList.map(checkData => checkData['hazardName']).join('、')
    },
    // 树形节点改变事件
    treeCheckChange(value, ifCheck) {
      if (ifCheck === false) {
        value['hazardName'] = value['hazardsName']
        this.msForm.showCheckDataList = []
      }
      if (ifCheck === true) {
        var codeNo = value.codeNo
        // 更新原有的hazardMap
        if (this.hazardMap != null) {
          this.hazardMap.set(codeNo, value.label)
        } else {
          var map = new Map()
          map.set(codeNo, value.label)
          this.hazardMap = map
        }
      }
      this.msForm.showCheckDataList.length = 0
      const showCheckDataList = this.$refs[this.treeRef] && this.$refs[this.treeRef].getCheckedNodes(this.ifJustLeaf)
      for (let i = 0, length = showCheckDataList.length; i < length; i++) {
        // 右侧集合 加一个危害因素明细名称 可修改
        this.$set(showCheckDataList[i], 'hazardsNameDetail', showCheckDataList[i]['hazardName'])
        if (this.$zwxBase.verifyIsNotBlank(this.hazardMap)) {
          let name = this.hazardMap.get(showCheckDataList[i]['codeNo'])
          if (this.$zwxBase.verifyIsNotBlank(name)) {
            showCheckDataList[i]['hazardName'] = showCheckDataList[i]['hazardsName']
            showCheckDataList[i]['hazardsNameDetail'] = name
          }
        }
        this.msForm.showCheckDataList.push(showCheckDataList[i])
      }
      this.checkDataList.length = 0
      this.input = ''
      for (let i = 0, length = this.msForm.showCheckDataList.length; i < length; i++) {
        this.checkDataList.push(this.msForm.showCheckDataList[i])
      }
      this.input = this.checkDataList.map(checkData => checkData['hazardName']).join('、')
      this.$forceUpdate()
      let data = {
        hazardNames: this.input,
        checkDataList: this.checkDataList,
      }
      this.$emit('change', data)
    },
    // 自定义危害因素名称修改 失焦事件
    hazardsNameDetailBlur(row) {
      var codeNo = row.codeNo
      var newNameDetail = row.hazardsNameDetail
      if (this.$zwxBase.verifyIsBlank(newNameDetail)) {
        return
      } else {
        // 更新原有的hazardMap
        if (this.hazardMap != null) {
          this.hazardMap.set(codeNo, newNameDetail)
        }
      }
    },
    // 树形节点过滤
    filterNodeMethod(query, data) {
      if (this.checkIfExcludeCode(data)) return false
      if (query === undefined || query === null || query === '') return true
      return data['hazardsName'].indexOf(query) !== -1
              || (data['pinyinCode'] && data['pinyinCode'].toUpperCase().indexOf(query.toUpperCase()) !== -1)
              || ('hazardsName' !== undefined && 'hazardsName' !== null && 'hazardsName' !== '' && data['hazardsName'].toUpperCase().indexOf(query.toUpperCase()) !== -1)
              || data.cas.indexOf(query) !== -1 || (this.$zwxBase.verifyIsNotBlank(data.cas) && data.cas.toUpperCase().indexOf(query.toUpperCase()) !== -1)
    },
    // popover显示
    popoverShow() {
      this.$refs[this.searchRef] && this.$refs[this.searchRef].focus()
    },
    // popover隐藏
    popoverHide() {
      this.search('')
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
      this.$refs[this.treeScrollbarRef] && this.$refs[this.treeScrollbarRef].scrollTop(-337)
      this.$refs[this.checkScrollbarRef] && this.$refs[this.checkScrollbarRef].scrollTop(-307)
    },
    // 搜索
    search(searchInput) {
      this.searchInput = searchInput
      this.$refs[this.treeRef] && this.$refs[this.treeRef].filter(this.searchInput)
      this.$refs[this.treeScrollbarRef] && this.$refs[this.treeScrollbarRef].scrollTop(-337)
    },
    // 删除选中项
    showCheckDataListDelete(index) {
      if (this.msForm.showCheckDataList.length <= index) return
      var codeNo = this.msForm.showCheckDataList[index].codeNo
      if (this.$zwxBase.verifyIsNotBlank(codeNo) && this.hazardMap != null) {
        // 将原有的hazardMap 对应的map值删掉
        this.hazardMap.delete(codeNo)
      }
      this.msForm.showCheckDataList.splice(index, 1)
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.msForm.showCheckDataList)
    },
    // 清空内容
    iconClick(e) {
      if (!(!this.disabled && this.clearable && this.input)) return
      this.input = ''
      this.checkDataList.length = 0
      this.search('')
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes([])
      e.stopPropagation()
    },
    // 判断是否是排除项
    checkIfExcludeCode(data) {
      if (this.excludeCodeList.indexOf(data['codeNo']) !== -1) return true
      if (data.dataLevelCode.indexOf('.') !== -1) {
        const index = this.excludeCodeList.findIndex(excludeCode => {
          return data.dataLevelCode.indexOf(excludeCode + '.') === 0 || data.dataLevelCode.indexOf('.' + excludeCode + '.') !== -1 || data.dataLevelCode.indexOf('.' + excludeCode) === data.dataLevelCode.length - ('.' + excludeCode).length
        })
        if (index !== -1) return true
      }
      return false
    },
    // 设置线形数据集合
    setLinearDataList(linearDataList) {
      if (linearDataList === undefined || linearDataList === null || linearDataList === '') return
      // 深拷贝集合，不动外部的集合
      const linearDataCopyList = JSON.parse(JSON.stringify(linearDataList))
      this.dataList.length = 0
      const dataList = []
      for (let i = 0, length = linearDataCopyList.length; i < length; i++) {
        linearDataCopyList[i].label = linearDataCopyList[i]['hazardsName']
        linearDataCopyList[i]['hazardName'] = linearDataCopyList[i]['hazardsName']
        linearDataCopyList[i].dataLevelCode = linearDataCopyList[i]['codeNo']
        if (this.$zwxBase.verifyIsNotBlank(linearDataCopyList[i]['cas'])) {
          linearDataCopyList[i]['cas'] = linearDataCopyList[i]['cas']
        } else {
          linearDataCopyList[i]['cas'] = ''
        }
        this.dataList.push(linearDataCopyList[i])
        dataList.push(linearDataCopyList[i])
      }
      // 线形转树形第一级
      this.linearDataListTreeFisrt(dataList)
      // 线形转树形递归
      this.linearDataListTreeRecursion(this.showDataList, dataList)
      // 清零临时数据
      dataList.length = 0
      // 回显
      this.echoCheckDataList()
    },
    // 设置过滤编码集合
    setExcludeCodeList(excludeCodeList) {
      this.excludeCodeList.length = 0
      for (let i = 0, length = excludeCodeList.length; i < length; i++) {
        this.excludeCodeList.push(excludeCodeList[i])
      }
      this.input = ''
      this.search('')
      if (this.showDataList.length === 0) return
      for (let i = 0, length = this.checkDataList.length; i < length; i++) {
        if (!this.checkIfExcludeCode(this.checkDataList[i])) continue
        this.checkDataList.splice(i, 1)
        length--
        i--
      }
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
      this.input = this.checkDataList.map(checkData => checkData['hazardsName']).join('、')
    },
    // 设置集合回显
    setEchoCheckDataCodeList(echoCheckDataCodeList, map) {
      this.hazardMap = map
      this.echoCheckDataCodeList.length = 0
      for (let i = 0, length = echoCheckDataCodeList.length; i < length; i++) {
        this.echoCheckDataCodeList.push(echoCheckDataCodeList[i])
      }
      // 回显
      this.$nextTick(() => {
        this.echoCheckDataList()
      })
    },
    // 获取选中的集合
    getCheckDataList() {
      const checkDataList = JSON.parse(JSON.stringify(this.checkDataList))
      for (let i = 0, length = checkDataList.length; i < length; i++) {
        delete checkDataList[i].label
        delete checkDataList[i].dataLevelCode
      }
      return checkDataList
    },
    /**
     * 电话校验
     */
    validatePhone(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.mbPeLdline(rule, value, callback)
      }
      return callback()
    },
  },
}
</script>

<style lang="less" scoped>
  /deep/.zwx-table .zwx-select .el-input__icon {
     line-height: unset !important;
  }
.add-new-job-css {
  /deep/.zwx-table::before {
    background-color: #fff !important;
  }
  /deep/.el-table td {
    padding: 0 !important;
    height: 36px !important;
  }
  /deep/.zwx-table th {
    height: 36px !important;
  }
  /deep/.zwx-table td .cell,
  /deep/.zwx-table th .cell {
    line-height: 21px !important;
    padding: 0 14px !important;
  }
  // 隐藏滚动条
  /deep/.el-table__body-wrapper::-webkit-scrollbar {
    /* width: 0;宽度为0隐藏 */
    width: 0px;
  }
  // 隐藏滚动条
  /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 50px;
    background: #fff;
  }
  // 隐藏滚动条
  /deep/.el-table__body-wrapper::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // border-radius: 2px;
    // background: rgba(0, 0, 0, 0.4);
  }
}

//添加岗位弹框   隐藏滚动条上方的一个方块
/deep/.el-table th.gutter {
  background: transparent !important;
  border: none !important;
}

/deep/.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fff !important; //背景色
  color: blue !important;
}
// 弹框的  tree   checkbox选择框颜色
/deep/.el-checkbox__inner {
  border: 1px solid #316cf5 !important;
}

.import-dept {
  position: fixed;
  left: 105px;
  top: 9px;
}
.department-station {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;

  .station-list {
    width: 450px;
    height: calc(100vh - 44px);
    border-right: 1px solid #e6e6e6;

    .station-list-title {
      height: 40px;
      padding-left: 20px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      text-align: left;
      color: #000000;
      line-height: 40px;
    }

    .station-search {
      padding: 12px 0px 12px 12px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .equipment-detail {
    width: calc(100% - 450px);

    .header-title {
      padding: 6px 20px;
      font-size: 16px;

      .zwx-button {
        margin-left: 24px;
      }
    }
  }
}
/deep/ .el-tree {
  color: #000000;
  line-height: 38px !important;
  // 将每一行的设置为相对定位 方便后面before after 使用绝对定位来固定位置
  // overflow: auto; //注释这个  可以清除弹框的 树 的 纵向滚动条
  .el-tree-node {
    position: relative;
    padding-left: 0px;
  }
  // 子集像右偏移 给数线留出距离
  .el-tree-node__children {
    padding-left: 42px;
    color: #606266;
    line-height: 30px !important;
  }
  //这是竖线
  .el-tree-node :last-child:before {
    height: 40px;
  }
  .el-tree > .el-tree-node:before {
    border-left: none;
  }
  .el-tree > .el-tree-node:after {
    border-top: none;
  }
  //这自定义的线 的公共部分
  .el-tree-node:before,
  .el-tree-node:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }
  .tree :first-child .el-tree-node:before {
    border-left: none;
  }
  // 竖线
  .el-tree-node:before {
    border-left: 1px solid #b3b7cc;
    bottom: 0px;
    height: 100%;
    top: -25px;
    width: 1px;
  }
  //横线
  .el-tree-node:after {
    border-top: 1px solid #b3b7cc;
    height: 20px;
    top: 14px;
    width: 6px;
  }
  .el-tree-node__expand-icon.is-leaf {
    width: 10px;
  }
  //每一行的高度
  .el-tree-node__content {
    // line-height: 30px;
    height: 30px;
    padding-left: 5px !important;
  }
  .el-tree-node__content:hover {
    background-color: unset !important;
    color: blue;
  }
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #fff !important; //背景色
    color: blue !important;
  }

  .table-item-add-icon {
    float: right;
    justify-content: flex-end;
  }
}
//去掉最上级的before  after 即是去电最上层的连接线
/deep/ .el-tree > div {
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}
.jobInfo-add-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 1068px !important;
      min-width: 1068px !important;
    }
  }
}
/deep/ .zwx-select .el-input__inner {
  width: 200px !important;
}
.multi-select-main .left {
  width: 42% !important;
  height: 430px;
  border-right: 1px solid #dcdddf;
  padding-left: 12px !important;
  padding-top: 8px !important;
  font-size: 12px;
}

.multi-select-main .options {
  height: calc(100% - 50px);
}

.right {
  width: 0 !important;
  height: 430px;
  flex: 201 !important;
  background: #ffffff !important;
  border-top: unset !important;
  font-size: 12px;
  .right-check {
    height: 38px !important;
    line-height: 38px !important;
    border-top: unset !important;
  }
  &-check {
    width: calc(100% - 1px) !important;
    height: 30px !important;
    line-height: 30px !important;
    padding-left: 12px !important;
    margin: 0px 0 0 1px !important;
    font-size: 12px !important;
    font-family: PingFangSC, PingFangSC-Regular !important;
    font-weight: 400 !important;
    color: #000000 !important;
    background: #ffffff !important;
    // box-shadow: 0px -1px 0px 0px #d3dce6, 0px -1px 0px 0px #d3dce6 inset !important;
    // border-bottom: none !important;
  }
  &-item {
    width: 100% !important;
    height: 40px !important;
    padding-left: 9px !important;
    padding-right: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    & > div {
      line-height: 12px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      color: #202937 !important;
      text-align: end;
    }
  }
}
.name-hidden {
  white-space: nowrap;
  width: 80px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.hazard-name-hidden {
  white-space: nowrap;
  /*width: 500px;*/
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left;
  font-size: 14px;
}
/deep/ .zwx-cascader-single-depart .zwx-input {
  width: 200px !important;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  // 设置颜色
  background-color: rgba(135, 206, 235, 0.2); // 透明度为0.2的skyblue，作者比较喜欢的颜色
  color: #409eff; // 节点的字体颜色
  font-weight: bold; // 字体加粗
}
  /deep/ .el-tree-node__content>label.is-disabled {
    display: none;
    margin-right: unset;
  }
</style>
<style lang="less"></style>
